<template>
  <div>Omschakelen van gebruiker</div>
</template>

<script>
  export default {
    name: 'Impersonate',
    props: {
      id: String,
    },
    mounted () {
      this.$http
        .get('users/' + this.id + '/impersonate-token')
        .then(response => {
          let adminToken = localStorage.getItem('jwt')
          localStorage.setItem('adminToken', adminToken)
          localStorage.setItem('adminUser', this.$root.$data.me)
          localStorage.setItem('jwt', response.data.token)
          window.location.href = '/'
        }).catch(function () {

        })
    }
  }
</script>